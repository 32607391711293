<template>
  <div class="error">
    <h1>404</h1>
    <p>Sorry, this page can't be found.</p>
  </div>
</template>

<script>
export default {
  name: 'error-404',
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: column;
}
</style>
